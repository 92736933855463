.card-div {
  padding: 25px 15px;
}

.cardDark {
  color: #161617;
}

.cardLight {
  color: #eceff1;
}


.gaugeGradient {
  background-color: #2196f3;
  margin: 0 auto;
}

.cardContainer {
  overflow-x: hidden;
}


/* large widths */

.firstCard.largeWidth.firstPosition {
  margin: 0 14px 15px 610px;
}

.firstCard.largeWidth.thirdPosition {
  /* margin: 0 14px 25px 610px; */
  margin: 0 14px 15px;
}

.firstCard.largeWidth.fifthPosition {
  margin: 0 14px 15px;
}

.lastCard.largeWidth.firstPosition {
  /* margin: 0 14px 25px 610px; */
  margin: 0 14px 15px;
}

.lastCard.largeWidth.thirdPosition {
  margin: 0 14px 15px;
}

.lastCard.largeWidth.fifthPosition {
  margin: 0 610px 15px 14px;
}


/* medium widths */

.firstCard.mediumWidth.firstPosition {
  margin: 0 6px 15px 490px;
}

.firstCard.mediumWidth.thirdPosition {
  margin: 0 6px 15px;
}

.firstCard.mediumWidth.fifthPosition {
  margin: 0 6px 15px;
}

.lastCard.mediumWidth.firstPosition {
  margin: 0 6px 15px;
}

.lastCard.mediumWidth.thirdPosition {
  margin: 0 6px 15px;
}

.lastCard.mediumWidth.fifthPosition {
  margin: 0 490px 15px 6px;
}


/* small widths */

.firstCard.smallWidth.firstPosition {
  margin: 0 35px 5px 1485px;
}

.firstCard.smallWidth.secondPosition {
  margin: 0 35px 5px 775px;
}

.firstCard.smallWidth.thirdPosition {
  margin: 0 35px 5px;
}

.firstCard.smallWidth.fourthPosition {
  margin: 0 35px 5px;
}

.firstCard.smallWidth.fifthPosition {
  margin: 0 35px 5px;
}

.lastCard.smallWidth.firstPosition {
  margin: 0 35px 5px;
}

.lastCard.smallWidth.secondPosition {
  margin: 0 35px 5px;
}

.lastCard.smallWidth.thirdPosition {
  margin: 0 35px 5px;
}

.lastCard.smallWidth.fourthPosition {
  margin: 0 775px 5px 35px;
}

.lastCard.smallWidth.fifthPosition {
  margin: 0 1485px 5px 35px;
}


/* extra-small widths */

.firstCard.xsmallWidth.firstPosition {
  margin: 0 38px 0 1215px;
}

.firstCard.xsmallWidth.secondPosition {
  margin: 0 38px 0 625px;
}

.firstCard.xsmallWidth.thirdPosition {
  margin: 0 38px 0 8px;
}

.firstCard.xsmallWidth.fourthPosition {
  margin: 0 38px 0 8px;
}

.firstCard.xsmallWidth.fifthPosition {
  margin: 0 38px 0 8px;
}

.lastCard.xsmallWidth.firstPosition {
  margin: 0 38px 0 8px;
}

.lastCard.xsmallWidth.secondPosition {
  margin: 0 38px 0 8px;
}

.lastCard.xsmallWidth.thirdPosition {
  margin: 0 38px 0 8px;
}

.lastCard.xsmallWidth.fourthPosition {
  margin: 0 625px 0 38px;
}

.lastCard.xsmallWidth.fifthPosition {
  margin: 0 1215px 0 38px;
}



.midCards {
  margin: 0 14px 15px;
}

/* .midCards.largeWidth.fifthPosition {
  border: 1px solid green;
} */

.firstCard.largeWidth {
  transition: margin-left 250ms ease;
}

.lastCard.largeWidth {
  transition: margin-right 250ms ease;
}

.firstCard.mediumWidth {
  transition: margin-left 250ms ease;
}

.lastCard.mediumWidth {
  transition: margin-right 250ms ease;
}

.firstCard.smallWidth {
  transition: margin-left 250ms ease;
}

.lastCard.smallWidth {
  transition: margin-right 250ms ease;
}

.firstCard.xsmallWidth {
  transition: margin-left 250ms ease;
}

.lastCard.xsmallWidth {
  transition: margin-right 250ms ease;
}


.gaugeGradient.largeWidth.firstPosition, .gaugeGradient.mediumWidth.firstPosition{
  margin: 0 auto 0 0;
}

.gaugeGradient.largeWidth.thirdPosition, .gaugeGradient.mediumWidth.thirdPosition {
  margin: 0 auto;
}

.gaugeGradient.largeWidth.fifthPosition, .gaugeGradient.mediumWidth.fifthPosition {
  margin: 0 0 0 auto;
}

.gaugeGradient.smallWidth.firstPosition, .gaugeGradient.xsmallWidth.firstPosition {
  margin: 0 auto 0 0;
}

.gaugeGradient.smallWidth.secondPosition, .gaugeGradient.xsmallWidth.secondPosition {
  margin: 0 auto 0 17%;
}

.gaugeGradient.smallWidth.thirdPosition, .gaugeGradient.xsmallWidth.thirdPosition {
  margin: 0 auto;
}

.gaugeGradient.smallWidth.fourthPosition, .gaugeGradient.xsmallWidth.fourthPosition {
  margin: 0 17% 0 auto;
}

.gaugeGradient.smallWidth.fifthPosition, .gaugeGradient.xsmallWidth.fifthPosition {
  margin: 0 0 0 auto;
}


@media only screen and (max-width: 1199px) {
  .midCards {
    margin: 0 8px 15px;
  }
}

@media only screen and (max-width: 899px) {
  .midCards {
    margin: 0 50px 5px;
    /* padding: 0 0 10px; */
  }
}

  @media only screen and (max-width: 599px) {
    .midCards {
      margin: 0 40px 0;
    }
  }
