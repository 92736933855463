@supports(padding:max(0px)) {
    body {
        padding-left: min(0vmin, env(safe-area-inset-left));
        padding-right: min(0vmin, env(safe-area-inset-right));
    }
}

.App {
  text-align: center;
  margin: 0 auto;
  overflow-x: hidden;
  width: 100%;
  background: linear-gradient(120deg, #303236, #242426);
  box-sizing: border-box;
}

.Construction {
  height: 100vh;
}

.construction-pic {
  width: 100%;
  height: auto;
}

.log-in:hover {
  cursor: pointer;
}

.light-font {
  color: #ffffffb3;
}

.logo {
  max-width: 100%;
  width: 150px;
  height: 48px;
}

.popper-logo {
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
}

.store-selected {
  opacity: 1;
  border: 2px solid #2196f3;
}

.store-not-selected {
  opacity: 0.6;
  border: 2px solid rgba(73, 177, 242, 0);
  transition: opacity 300ms;
}

.store-not-selected:hover {
  opacity: 1;
}

.modal-style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  max-width: 650px;
  max-height: 72%;
  border-radius: 5px;
  overflow: scroll;
  background-color: #fefefe;
  padding: 18px;
}

.help-icon {
  cursor: pointer;
  padding: 5px 5px 5px 0;
  color: #ffffffb3;
}

.down-arrow-icon {
  cursor: pointer;
  padding: 5px 5px 5px 5px;
  /* color: #ffffffb3; */
  color: #8e9399;

}

.down-arrow-icon:hover {
  color: #2196f3;
}

.toSelectorDiv {
  cursor: pointer;
  transition: all 200ms;
}

.toSelectorDiv:hover {
  background-color: rgba(33, 148, 243, 0.1);
}

.card-button-disabled {
  color: red;
  background: blue;
  box-shadow: none;
  width: 170px;
  height: 36px;
  padding: 6px 16px;
}

.toSelector:hover {
  opacity: 0.7;
}

.toSelector p {
  padding: 5px 5px 0 5px;
  margin: 5px 5px 0 5px;
  color: #303236;
}

.looks-icon {
  margin: 2px 8px 2px 2px;
  color: #2196f3;
}

.tom-range, .start-over {
  color: #ffffffb3;
}

.help-icon:hover {
  opacity: 0.9;
}

.start-over {
  cursor: pointer;
  padding: 5px;
}

.start-over-error {
  text-decoration: underline;
}

.subhead-text-box {
  /* width: 40%; */
  /* min-width: 350px; */
}

.sub-pic-box {
  /* width: 48%; */
}

.drum-image {
  width: 100%;
  /* height: auto; */
}

.bottomSnareDiv img {
  width: 85%;
}

.bottom-head-background {
  background-size: 340px;
  background-position: 50% 10%;
  background-repeat: no-repeat;
}

.SNARE.Remo {
  background-image: url('https://drumheadselector.b-cdn.net/remo-ambassador-hazy.webp');
}

.SNARE.Evans {
  background-image: url('https://drumheadselector.b-cdn.net/evans-snare-side-300.webp');
}

.TOMS.Remo {
  background-image: url('https://drumheadselector.b-cdn.net/remo-ambassador-clear.webp');
}

.TOMS.Evans {
  background-image: url('https://drumheadselector.b-cdn.net/evans-g1-clear.webp');
}

.tips-products img {
  width: 170px;
  height: 170px;
}

.affiliate-modal-text {
  cursor: pointer;
}

.featured-head-image img {
  width: 295px;
}

.featured-link {
  color: #2196f3;
  cursor: pointer;
  text-decoration: underline;
}

.featured-link:hover {
  opacity: 0.9;
}


@keyframes slideUp {
  0% {
    transform: translateY(105%);
    opacity: 0;
  }
  85% {
    transform: translateY(105%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media only screen and (max-width: 1199px) {
  .bottom-head-background {
    background-size: 310px;
  }
  .featured-head-image img {
    width: 260px;
    height: 260px;
  }
}

@media only screen and (max-width: 899px) {
  .down-arrow-icon {
    color: #2196f3;
  }
  .bottom-head-background {
    background-size: 230px;
  }
  .tips-products img {
    width: 150px;
    height: 150px;
  }
}

@media only screen and (max-width: 599px) {
  .down-arrow-icon {
    color: #2196f3;
  }
  .bottom-head-background {
    background-size: 158px;
  }
  .tips-products img {
    width: 140px;
    height: 140px;
  }
}
